import './css/App.css';
import React, { Component } from 'react';
import { TableContainer, Paper,
    FormControl, Select, InputLabel, MenuItem, Table, TableBody, TableCell,
	TableHead, TableRow, } from '@material-ui/core';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
// import Header from './Header';
import UserHeader from './UserHeader';
import InfiniteScroll from "react-infinite-scroll-component";
import SafeTechSnackbar from './SafeTechSnackbar';
import AppHelper from '../Helper/AppHelper';

class UserHistory extends Component {

	constructor(props) {
		super(props)
		this.state = {
			spinner: 'none',
            snackMsg: '',
            snackVariant: 'error',
			snack: false,
			BUTTONS: false,
            logSpinner: 'none',
            userLogs: [],
            filterEvent: 'all',
            logPage: 1,
			limit: 20,
        }
	}

    componentDidMount(){
        // console.log('on user history state : ',this.props);
        if (this.props.state.token === '' || this.props.state.user_type === 'Admin') {
			if(this.props.state.token === ''){
                window.location.href = "/"
            }else{
                window.location.href = "/AccountList"
            }
		}
		else {
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true, snackVariant: 'error' })
				this.props.UpdateMSG('')
			}
            // this.fetchCardListing()
            this.fetchUserLogs(this.state.logPage)
        }
    }

    fetchMoreLogData = () => {
		if (this.state.userLogs.length == (this.state.logPage * this.state.limit)) {
			this.setState({
				logPage: this.state.logPage + 1, spinner: true
			},
			() => {
				this.fetchUserLogs(this.state.logPage)
			});
		}
		else {
			this.setState({ hasMoreLogData: false, spinner: false, spinner: 'none' })
		}
    }

    handleChangeFilterEvent = (event) => {
		// console.log('called handleChangeFilterEvent :', event.target.value)
		this.setState({ userLogs: [], filterEvent: event.target.value, logPage: 1, logSpinner: 'block'},
		() => {
			this.fetchUserLogs(this.state.logPage)
		})
	}

    async fetchUserLogs(page) {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			// console.log("GetUserLogs : ", URLHelper.GetUserLogs + "/" + this.props.state.user_id + "/" + page);
			return fetch(URLHelper.GetUserLogs + "/" + this.props.state.user_id + "/" + page + "/" + this.state.filterEvent, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 404) {
					// this.setState({ spinner: 'none', userLogs: [], hasMoreLogData: false })
					this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs})
				}
				else {
					Response.json()
						.then((res) => {
							this.setState({ logSpinner: 'none', spinner: 'none', BUTTONS: true })
							if (res.status) {
								// console.log("result log list : ", res)
								let newLogList = (this.state.filterEvent !== '' && page === 1) ? [] : this.state.userLogs
								for (let i = 0; i < res.data.length; i++) {
									//console.log(res.data[i])
									newLogList.push({ date: res.data[i].date, event_name: res.data[i].event_name, station: res.data[i].station, amount: parseFloat(res.data[i].amount).toFixed(2), running_balance: res.data[i].running_balance })
								}
								this.setState({ userLogs: newLogList, hasMoreLogData: res.data.length < this.state.limit ? false : true })
							}
							else {
								this.setState({ snackMsg: res.message, hasMoreLogData: false, snack: res.message ? true : false, BUTTONS: true, spinner: 'none', userLogs: [] })
							}
						})
						.catch((error) => {
                            console.log(error)
							this.setState({ logSpinner: 'none',
								spinner: 'none', button: '', hasMoreLogData: false, snackMsg: "Something went wrong.",
								snack: true, snackVariant: 'error', BUTTONS: true, userLogs: []
							})
						})
				}
			}).catch((error) => {
				this.setState({ logSpinner: 'none',
					spinner: 'none', button: '', hasMoreLogData: false, snackMsg: "Something went wrong.",
					snack: true, snackVariant: 'error', BUTTONS: true, userLogs: []
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", hasMoreLogData: false, snack: true, snackVariant: 'error', userLogs: [] })
		}
    };
    
    hideSnackBar = () => {
		this.setState({ snack: false })
	}

	render() {
		if (this.props.state.token !== '' && this.props.state.user_type === 'User') {
			return (
				<div>
					{/* <Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar> */}
                    <SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
					{/* <Header propsState={this.props.state} /> */}
					<UserHeader propsState={this.props.state} />
                    
					<div>
						<div className="sm:p-10">
						    <div className='user-body-card py-6 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden shadow-lg bg-white'>
                                <div className="flex justify-between items-center">
                                    <div className="text-center pl-12">
                                        <span className="text-primary text-sm">History</span>
                                    </div>
                                    <div className="text-center pr-12">
                                        <FormControl className="w-40">
                                            <InputLabel id="demo-simple-select-label">Filter Log Event</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.filterEvent}
                                            onChange={(event) => this.handleChangeFilterEvent(event)}
                                            >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            <MenuItem value={'refill'}>Refill</MenuItem>
                                            <MenuItem value={'manual-refill'}>Manual Refill</MenuItem>
                                            <MenuItem value={'sign-in-out'}>Sign in/out</MenuItem>
                                            <MenuItem value={'deduction-logs'}>Deduction Logs</MenuItem>
                                            <MenuItem value={'print-logs'}>Printer Logs</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="py-6">
                                    <div id="account-list-scroll" className="overflow-y-scroll text-sm text-safeFormGray" style={{ height: '400px' }}>
                                        <InfiniteScroll
											dataLength={this.state.userLogs.length}
											next={this.fetchMoreLogData}
											hasMore={this.state.hasMoreLogData}
											// loader={<h4>Loading...</h4>}
											scrollableTarget="account-list-scroll"
										>
											<TableContainer component={Paper}>
												<Table className="rounded" size="small" aria-label="logs table">
													<TableHead className="bg-primary rounded">
														<TableRow>
															<TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Date</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Event</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Station</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Amount</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Running Minutes</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Running Balance</span></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.userLogs.length > 0 && this.state.userLogs.map((row, index) => (
															<TableRow key={index}>
																<TableCell component="th" scope="row">
																	{row.date}
																</TableCell>
																<TableCell>{row.event_name}</TableCell>
																<TableCell>{row.station}</TableCell>
																<TableCell style={{ color: (row.amount < 0) ? 'red' : 'blue' }}>
																	{row.amount < 0 ? '$' + row.amount * -1 : '$' + row.amount}
																</TableCell>
																<TableCell>{row.running_balance > 0 ? (row.running_balance / AppHelper.chargePerMinute).toFixed(0) : 0}</TableCell>
																<TableCell style={{ color: (row.running_balance < 0) ? 'red' : 'black' }}>{'$'+(row.running_balance).toFixed(2)}</TableCell>
															</TableRow>
														))}
														{
															(this.state.logSpinner === 'block') &&
															<TableRow key='loader'>
																<TableCell colSpan={6}>
																	<div style={{ width: '100%', alignItems: 'center', display: this.state.logSpinner }}>
																		<center><CircularProgress className="align-baseline" /></center>
																	</div>
																</TableCell>
															</TableRow>
														}
													</TableBody>
												</Table>
											</TableContainer>
										</InfiniteScroll>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHistory);
