import './css/App.css';
import React, { Component } from 'react';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Button, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import UserHeader from './UserHeader';
import AdminHeader from './AdminHeader';
import clsx from 'clsx';
import plansCardBgPng from '../assets/plans_card_bg.png';
import AppHelper from '../Helper/AppHelper';

class Plans extends Component {
	
	constructor(props) {
		super(props)
		this.state = {
			plans: [],
			user_name: '',
			first_name: '',
			credit: '',
			min_credit: '',
			credit_display: '',
			spinner: 'none',
			snackMsg: '',
			snack: false,
			BUTTONS: false,
			modalOpen: false,
			password: '',
			confirm_password: '',
			openCancel: false
		}
	}
	
	componentDidMount() {
		// console.log('componentDidMount on plan state : ',this.props);
		if (this.props.state.token === '') {
			window.location.href = "/"
		}
		else {
			if(!(this.props.location && this.props.location.state && this.props.location.state.PropsData)){
				if (this.props.state.token !== '') {
					if(this.props.state.user_type === 'Admin'){
						window.location.href = "/AccountList"
					}else{
						window.location.href = "/MainMenu"
					}
				}else{
					window.location.href = "/"
				}
			}
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true })
				this.props.UpdateMSG('')
			}
			this.fetchPlanListing();
			// this.fetchUserData()
		}
	}

	async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						let planList = [];
						if(res.data.length > 0)
						{
							res.data.forEach((element) => {
								// console.log('element ::::', element)
								planList.push(element)
							})
						}
						this.setState({ plans: planList })
					}
					else {
						this.setState({ spinner: 'none', plans: [], snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', plans: [], snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	async handleCancelMonthlySubscription() {
		if (navigator.onLine) {
            // console.log('this.props.location.state.PropsData ::::',this.props.location.state.PropsData);
            this.setState({ spinner: 'block', BUTTONS: false })
			const { user_monthly_subscription_id } = this.props.state
            return fetch(URLHelper.CancelMonthlySubscription, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify({ user_monthly_subscription_id })
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                if (Response.status == 404) {
                    this.setState({ spinner: 'none' })
                } else {
                    Response.json().then((res) => {
                        this.setState({ spinner: 'none', BUTTONS: true })
                        if (res.status) {
                            // console.log('RES ::',res)
							const { action_by } = this.props.location.state.PropsData;
							this.setState({ snackMsg: res.message, snack: true, snackVariant: 'success', BUTTONS: true })
							const { credit_balance } = this.props.state
							if(action_by === 'admin'){
								this.props.UpdateCustomer({ ...this.props.state, user_monthly_subscription_id: '', is_active_monthly_subscription: 0 })
							}else{
								this.props.CustomerDetail({ ...this.props.state, credit: credit_balance, user_monthly_subscription_id: '', is_active_monthly_subscription: 0 })
							}
                        }
                        else {
                            this.setState({ spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                        }
                    })
                        .catch((error) => {
                            console.log(error)
                            this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
                        })
                }
            })

        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
	}
    
	listItems = (plansArray, refillType, actionBy, userId) => {
		const { is_active_monthly_subscription } = this.props.state
        return (
		plansArray.map(plan =>
			<div key={plan.plan_id} className="py-6 px-4 w-6/12 lg:w-4/12 xl:w-3/12" style={{ height: '206px' }}>
				<div className={clsx(plan.is_unlimited ? (refillType === 'refill' ? 'opacity-100' : 'opacity-75 hidden') : 'opacity-100', "plan-items-card bg-contain bg-top border py-2 my-2")} style={{ backgroundImage: `url(${plansCardBgPng})` }}>
					{
						(plan.bonus > 0) &&
						<div className="-mt-6" style={{ marginLeft: '90%'}}>
							<button type="button"
								className="bg-safeYellow h-10 w-10 p-1 text-white rounded-lg font-sans text-xs">
								Save <span className="text-smXs">{(plan.bonus * 100 / plan.price) % 1 != 0 ? (plan.bonus * 100 / plan.price).toFixed(2) : (plan.bonus * 100 / plan.price)}%</span>
							</button>
						</div>
					}
					<div className={clsx((plan.bonus > 0) && '-mt-4', 'flex flex-col items-center')}>
						<span className="pt-4 pb-24 text-sm md:text-lg">{plan.is_unlimited ? 'Monthly Subscription' : this.convertTime(plan.price + plan.bonus)}</span>
						{
							plan.is_unlimited ?
							<>
								{
									(refillType === 'refill' && is_active_monthly_subscription) ?
									<Tooltip title="Cancel Monthly Subscription" placement="bottom">
										<button onClick={() => this.setState({ openCancel: true })} type="button" className="bg-safeRed w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
											<span className="text-sm">Cancel</span>
										</button>
									</Tooltip>
									:
									<button disabled={refillType === 'refill' ? false : true} style={{ cursor: refillType === 'refill' ? 'pointer' : 'not-allowed' }} onClick={() => { this.props.history.push("/PaymentMethod",
									{ PropsData:
										{ type: refillType, is_unlimited: plan.is_unlimited, plan_id: plan.plan_id, price: plan.price, bonus: plan.bonus, actionBy, userId} }
										)}} type="button" className={clsx(refillType === 'refill' ? 'cursor-pointer' : 'opacity-75 cursor-not-allowed', "bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base")}>
										<span className="text-xs">$ </span>{ plan.price.toFixed(2) }
									</button>
								}
							</>
							:
							<button  onClick={() => { this.props.history.push("/PaymentMethod",
							{ PropsData:
								{ type: refillType, is_unlimited: plan.is_unlimited, plan_id: plan.plan_id, price: plan.price, bonus: plan.bonus, actionBy, userId} }
								)}} type="button" className="bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
								<span className="text-xs">$ </span>{ plan.price.toFixed(2) }
							</button>
						}
					</div>
				</div>
			</div>
		));
	}

	convertTime = (amount) => {
        // console.log('convertTime ::',amount)
        let givenSeconds = (amount / AppHelper.chargePerMinute) * 60;
        // console.log('givenSeconds ::',givenSeconds)
		let dateObj = new Date(givenSeconds * 1000); 
		let hours = dateObj.getUTCHours(); 
		let minutes = dateObj.getUTCMinutes();
        // let seconds = dateObj.getSeconds();

        if((amount / AppHelper.chargePerMinute) > 120){
            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
        }else{
            return (amount / AppHelper.chargePerMinute).toFixed(0) + ' Minutes'; 
        }
    }

	getChoosePlanHeading = (purchaseType = '') => {
		const { is_active_monthly_subscription } = this.props.state
		if(purchaseType === 'auto-refill' && is_active_monthly_subscription){
			return 'Choose Prints Auto Replenish Amount'
		}else if(purchaseType === 'auto-refill' && !is_active_monthly_subscription) {
			return 'Choose Auto Replenish Amount'
		}else if(purchaseType === 'refill' && is_active_monthly_subscription){
			return 'Choose Prints Refill Amount'
		} else if(purchaseType === 'refill' && !is_active_monthly_subscription){
			return 'Choose Refill Amount'
		}else{
			return ''
		}
	}

	render() {
		if (this.props.state.token !== '' && this.props.location && this.props.location.state && this.props.location.state.PropsData) {
			// console.log("this.props.location.state.PropsData ::",this.props.location.state.PropsData)
			const { type, action_by, user_id } = this.props.location.state.PropsData;
			return (
				<div>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar>

					<Dialog
                        open={this.state.openCancel}
                        onClose={() => this.setState({ openCancel: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">You want to cancel your monthly subscription?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ openCancel: false })} color="primary">
                                No
                            </Button>
                            <Button onClick={() => {
                                this.setState({ openCancel: false })
                                this.handleCancelMonthlySubscription()
                            }} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

					{
						(this.props.state.admin_first_name) ?
						<AdminHeader propsState={this.props.state} /> :
						<UserHeader propsState={this.props.state} />
					}
					<div className={clsx((this.props.state.admin_first_name !== '') ? "mt-12" : "mt-40"," w-11/12 sm:w-9/12 rounded mx-auto")}>
						<div className="text-center">
						<span className="text-safeFormGray text-base mx-auto">{this.getChoosePlanHeading(type)}</span>
						</div>
						<div className="py-2">
							<div className="flex flex-wrap text-lg font-sans text-white">
								<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
									<center><CircularProgress /></center>
								</div>
								{ this.listItems(this.state.plans, type, action_by, user_id ) }
								{/* {
									(this.state.BUTTONS) &&
									<div key={'static'} className="py-6 px-4 w-6/12 lg:w-4/12 xl:w-3/12" style={{ height: '206px' }}>
										<div className="plan-items-card bg-contain bg-top border py-2 my-2" style={{backgroundImage: `url(${plansCardBgPng})` }}>
											<div className='flex flex-col items-center'>
												<span className="pt-4 pb-18 sm:pb-24 text-center text-sm md:text-lg">Monthly Subscription</span>
												<button type="button" className="bg-primary w-32 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
													<span className="text-xs">Comming soon</span>
												</button>
											</div>
										</div>
									</div>
								} */}
							</div>
						</div>
					</div>
					
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		CustomerDetail: passData => { dispatch({ type: 'CustomerDetail', value: { ...passData } }) },
		UpdateCustomer: (value) => { dispatch({ type: 'UpdateCustomer', value }) },
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
