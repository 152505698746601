import React, { useState, useEffect } from 'react';
import { Grid, Radio, RadioGroup, FormControlLabel,
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, Select, InputLabel, MenuItem, } from '@material-ui/core';
import progressPng from '../assets/progress.png';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import URLHelper from '../Helper/URLHelper';
import { loadOptions } from '../Helper/loadOptions';
import { AsyncPaginate } from "react-select-async-paginate";


function AddEditCouponDialog(props){
    const { open, handleClose, setSnackMessage, currentCouponDetail, token, initialCouponDetail } = props.data
    const [couponData, setCouponData] = useState(currentCouponDetail)
    const [spinner, setSpinner] = useState('none')

    useEffect(() => {
        if(currentCouponDetail){
            // console.log("currentCouponDetail ::",currentCouponDetail)
            let promo = { ...currentCouponDetail }
            if(promo.coupon_id && promo.action_type === 'edit'){
                promo = { ...currentCouponDetail, for_all_user: promo.for_all_user ? 'all' : 'specific' }
                getCouponUsers(promo)
            }else{
                setCouponData(promo)
            }
        }
    },[currentCouponDetail])

    const getCouponUsers = async (passData) => {
        const { coupon_id } = passData
        try {
            const response = await fetch(
                URLHelper.GetCouponUsers + "/" + coupon_id,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'Application/json',
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + token
                    }
                }
            );
            const res = await response.json()
            // console.log("res ::",res)
            const { data, status } = res
            if(status){
                let selectedUsers = []
                let prevIds = []
                data.forEach(({ user_id, first_name, last_name }) => {
                    prevIds.push(user_id)
                    selectedUsers.push({ value: user_id, label: `${first_name} ${last_name}` })
                })
                // console.log("selectedUsers ::",selectedUsers)
                // console.log("prevIds ::",prevIds)
                setCouponData({ ...passData, users: selectedUsers, prev_users: prevIds })
            }else{
                setCouponData({ ...passData, users: [], prev_users: [] })
            }
        } catch (error) {
            console.log("Error ::",error)
            setCouponData({ ...passData, users: [], prev_users: [] })
        }
    };

    const addEditPromotion = async (fromData) => {
        if (navigator.onLine) {
            setSpinner('block')
            return fetch(URLHelper.AddEditCoupon, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(fromData)
            }).then((Response) => {
                if (Response.status === 404) {
                    setSpinner('none')
                }
                else {
                    Response.json()
                        .then((res) => {
                            setSpinner('none')
                            if (res.status) {
                                // console.log("result log list : ", res)
                                setSnackMessage(res.message, "success")
                                resetModal(true)
                            }
                            else {
                                setSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setSnackMessage("Something went wrong.", "error")
                            setSpinner('none')
                        })
                }
            }).catch((error) => {
                console.log(error)
                setSpinner('none')
                setSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            setSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    // console.log('couponData ::', couponData)
    const handleDateChange = (date, field) => {
    //   console.log('handleDateChange ::', date, field)
      setCouponData({ ...couponData, [field]: date })
    };

    const inputHandler = (event, field) => {
        // console.log('inputHandler ::',event)
        let newData = {}
        if(field === 'users'){
            newData = { [field]: event }
        }else if(field === 'for_all_user'){
            newData = { [field]: event.target.value, valid_for: event.target.value == 'all' ? 'A' : 'E' }
        }else{
            newData = { [field]: event.target.value }
        }
        // console.log('inputHandler value ::',value)
        setCouponData({ ...couponData, ...newData })
    }

    const couponFormSubmit = () => {
        // console.log('couponData ::',couponData)
        let msg = "";
        const { start_date, end_date, prev_users, for_all_user } = couponData
        let formData = {
            ...couponData,
            start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : '',
            end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : '',
        }
        if(formData.coupon_code === ''){
            msg += "coupon code is required, "
        }
        if(formData.start_date === ''){
            msg += "coupon start date is required, "
        }
        if(formData.end_date === ''){
            msg += "coupon end date is required, "
        }
        if(formData.discount === '' || (formData.discount !== '' && formData.discount == 0)){
            msg += "discount is required, "
        }
        if(formData.discount !== ''){
            if(formData.discount < 0){
                msg += "discount should be greater than zero, "
            }
            if(formData.discount_type == 'P' && formData.discount > 100){
                msg += "discount should not be grater than 100%, "
            }
        }
        if(formData.valid_for === 'E'){
            if(formData.for_all_user === 'specific'){
                if(!formData.users.length){
                    msg += "users are required, "
                }
            }
        }
        if(msg !== ""){
            console.log('msg::', msg)
            setSnackMessage(msg, "error")
        }else{
            // console.log('PRE passs ::',formData)
            if(formData.valid_for){
                if(['A','N'].includes(formData.valid_for)){
                    formData = { ...formData, users: '' }
                }
                if(formData.valid_for == 'N'){
                    formData = { ...formData, total_usage: 1 }
                }
            }
            if(formData.for_all_user){
                if(formData.for_all_user === 'all'){
                    formData = { ...formData, for_all_user: 1, users: '' }
                }else{
                    formData = { ...formData, for_all_user: 0 }
                }
            }
            if(formData.users && formData.users.length){
                let ids = []
                let remUsers = []
                formData.users.forEach(({ value }) => {
                    ids.push(value)
                });

                prev_users.forEach(value => {
                    if(!ids.includes(value)){
                        remUsers.push(value)
                    }
                });     
                formData = { ...formData, users: ids.toString(), prev_users: remUsers.toString() }
            }else{
                formData = { ...formData, users: '', prev_users: prev_users.toString() }
            }
            // console.log('POST passs ::',formData)
            addEditPromotion(formData)
        }
    }

    const resetModal = (reload = false) => {
        setCouponData(initialCouponDetail)
        handleClose(reload)
    }

    // const handleBlur = (event) => {
    //     console.log("Handle Blur ::",event.target.value)
    // }

    return(
        <Dialog
            open={open}
            maxWidth="xs"
            onClose={resetModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <span className="text-safeFormGray text-base ml-4">{`${couponData.action_type === 'add' ? 'Add' : 'Edit'} Coupon`}</span>
            </DialogTitle>
            <DialogContent>
                <div className="px-4">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="coupon_code"
                                label="Coupon code"
                                type="text"
                                className="w-full"
                                value={couponData.coupon_code}
                                onChange={event => inputHandler(event, "coupon_code")}
                                // onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="flex flex-row justify-between items-center w-full">
                                <span className="text-safeGray">Start: </span>
                                <div className='px-2'>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            // className="w-32"
                                            format="MM-dd-yyyy"
                                            placeholder="Coupon start date"
                                            // minDate={new Date()}
                                            // maxDate={moment(couponData.end_date).subtract(1, 'd')}
                                            value={couponData.start_date}
                                            onChange={event => handleDateChange(event,"start_date")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="text-safeGray">End: </span>
                                <div className='pl-2'>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            // className="w-32"
                                            format="MM-dd-yyyy"
                                            placeholder="Coupon end date"
                                            // minDate={moment(couponData.start_date).add(1, 'd')}
                                            value={couponData.end_date}
                                            onChange={event => handleDateChange(event,"end_date")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </Grid>

                        
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="flex flex-row justify-between items-center w-full">
                                <span className="text-safeGray">Discount type: </span>
                                <RadioGroup row aria-labelledby="row-discount-type-label" name="discount-type" value={couponData.discount_type} onChange={event => inputHandler(event,"discount_type")} >
                                    <FormControlLabel value="F" control={<Radio />} label="Flat rate" />
                                    <FormControlLabel value="P" control={<Radio />} label="Percent rate" />
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="discount"
                                label="Discount"
                                placeholder={couponData.discount_type === "F" ? "Flat rate" : "Percent rate"}
                                type="number"
                                className="w-full"
                                value={couponData.discount}
                                onChange={event => inputHandler(event, "discount")}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl className="w-full">
                                <InputLabel id="coupon-type-label">Coupon valid for</InputLabel>
                                <Select
                                    labelId="coupon-type-label"
                                    id="coupon-type"
                                    value={couponData.valid_for}
                                    onChange={event => inputHandler(event, "valid_for")}
                                >
                                    <MenuItem value={'E'}>Existing users</MenuItem>
                                    <MenuItem value={'N'}>New users</MenuItem>
                                    <MenuItem value={'A'}>All users</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            (couponData.valid_for === 'E') &&
                            <>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className="flex flex-row items-center w-full">
                                    <span className="text-safeGray mr-2">Allowed Users: </span>
                                    <RadioGroup row aria-labelledby="row-discount-type-label" name="discount-type" value={couponData.for_all_user} onChange={event => inputHandler(event,"for_all_user")} >
                                        <FormControlLabel value="all" control={<Radio />} label="All" />
                                        <FormControlLabel value="specific" control={<Radio />} label="Specific" />
                                    </RadioGroup>
                                </div>
                            </Grid>
                            {
                                ((couponData.for_all_user === 'specific') && (couponData.valid_for === 'E')) &&
                                <Grid item xs={12} sm={12} md={12}>
                                    <AsyncPaginate
                                        debounceTimeout={300}
                                        value={couponData.users}
                                        placeholder="Select Users"
                                        isMulti
                                        closeMenuOnSelect={false}
                                        loadOptions={loadOptions}
                                        onChange={ev => inputHandler(ev, 'users')}
                                        additional={{
                                            page: 1,
                                            url: URLHelper.GetUsers,
                                            token: token,
                                            type: 'user',
                                            coupon_id: couponData.coupon_id
                                        }}
                                    />
                                </Grid>
                            }
                            </>
                        }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <button
                    type="button"
                    disabled={(spinner === 'block')}
                    className="text-safeRed w-32 py-1 float-right font-sans font-semibold text-xs md:text-sm"
                    onClick={() => resetModal() }>Back
                </button>
                <button
                    type="button"
                    disabled={(spinner === 'block')}
                    onClick={() => couponFormSubmit() }
                    className="bg-primary w-32 float-left text-white py-4 my-2 mr-8 shadow-xl rounded font-sans text-xs md:text-sm">
                    {(spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Save' }
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEditCouponDialog;